import { useState, useRef } from "react"
import { NavBar } from "../../Components/NavBar"
import Servicos from "../../img/pictures/servicos.jpg"
import Usinagem from '../../img/pictures/usinagem_img.jpg'
import Desmontagem from '../../img/pictures/desmontagem.jpg'
import Montagem from '../../img/pictures/montagem.jpg';
import { CardsServices } from "../../Components/CardsServices";
import { Footer } from "../../Components/Footer"

type Props = {
    handleBUttonCardClick: (div: React.RefObject<HTMLDivElement>) => void,
    usinagemRef: React.RefObject<HTMLDivElement>,
    desmontagemRef: React.RefObject<HTMLDivElement>,
    montagemRef: React.RefObject<HTMLDivElement>
}

export const Services = () => {
    const usinagemRef = useRef<HTMLDivElement>(null);
    const desmontagemRef = useRef<HTMLDivElement>(null);
    const montagemRef = useRef<HTMLDivElement>(null);

    const handleBUttonCardClick = (div: React.RefObject<HTMLDivElement>) => {
        div.current?.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <div className="bg-primary w-screen font-poppins font-light">
            <header>
                <NavBar />
            </header>
            <main>
                <section className="bg-neutral">  
                    <div className="h-64 mx-auto" style={{ backgroundImage: `url(${Servicos})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                        <div className="w-full h-full bg-neutral/50">
                            <div className="lg:w-[1024px] flex items-center h-full mx-auto text-primary">
                                <div className="lg:px-0 p-5">
                                    <h2 className="pb-4 text-xl">Conheça nossos</h2>
                                    <h1 className="lg:text-7xl md:text-7xl text-6xl font-semibold text-primary">Serviços</h1>
                                </div>
                            </div>
                        </div>
                    </div>   
                </section>
                <section>
                    <CardsServices props={{ handleBUttonCardClick, usinagemRef, desmontagemRef, montagemRef }} />
                </section>
                <section className="bg-secondary">
                    <div ref={usinagemRef} className="lg:w-[1024px] px-5 py-10 mx-auto flex gap-10 lg:flex-row md:flex-row flex-col items-center">
                        <div className="lg:w-2/4 md:w-2/4 w-full">
                            <h1 className="text-2xl font-semibold text-primary pb-5">Usinagem</h1>
                            <p className="text-primary text-md pb-4">
                                Durante a usinagem, é feita toda a retífica de seu motor, 
                                de maneira sequencial, por profissionais altamente qualificados.
                            </p>
                            <p className="text-primary text-md pb-2">
                                Maquinários de precisão garante uma ótima retifica
                            </p>
                            <div className="text-primary text-md">
                                <ul className="list-disc ps-7">
                                    <li>Retífica de Virabrequim</li>
                                    <li>Faceadora de Cabeçote</li>
                                    <li>Brunidora de Cilindro</li>
                                    <li>Bloqueadora de Cilindro</li>
                                    <li>Mandrilhadora de Mancais</li>
                                    <li>Retífica de Bielas</li>
                                    <li>Polimento de Virabrequins</li>
                                    <li>Retífica de Válvulas</li>
                                    <li>Prensa Hidráulica</li>
                                    <li>Faceadora de Assento e Camisa</li>
                                    <li>Balanceadora eletronica</li>
                                    <li>Magnaflux teste de virabrequim</li>
                                </ul>
                            </div>
                        </div>
                        <div className="lg:w-2/4 md:w-2/4 w-full">
                            <img src={Usinagem} alt="Homem usinando um motor" className="mx-auto"></img>
                        </div>
                    </div>
                </section>
                <section>
                    <div ref={desmontagemRef} className="lg:w-[1024px] px-5 py-10 mx-auto flex gap-10 lg:flex-row md:flex-row flex-col items-center">
                        <div className="lg:w-2/4 md:w-2/4 w-full">
                            <h1 className="text-2xl font-semibold pb-5">Desmontagem</h1>
                            <p className="text-md pb-4">
                                A Retificadora Maringá conta com especialistas em cada 
                                segmento do seu motor, que realizam desmontagens meticulosas 
                                para identificar prontamente qualquer problema no motor.
                                Além disso, oferecemos estacionamento para veículos, 
                                especialmente destinado à retirada de motores. 
                                Os veículos em serviço desfrutam de total segurança nas 
                                instalações da Retificadora Maringá, proporcionando assim 
                                maior comodidade aos nossos clientes.
                            </p>
                            <p>
                                Equipamentos apropriados para retirar seu motor, o que traz agilidade e segurança.
                            </p>
                        </div>
                        <div className="lg:w-2/4 md:w-2/4 w-full">
                            <img src={Desmontagem} alt="Homem desmontando um motor" className="mx-auto"></img>
                        </div>
                    </div>
                </section>
                <section className="bg-secondary">
                    <div ref={montagemRef} className="lg:w-[1024px] px-5 py-10 mx-auto flex gap-10 lg:flex-row md:flex-row flex-col items-center">
                        <div className="lg:w-2/4 md:w-2/4 w-full">
                            <h1 className="text-2xl text-white font-semibold pb-5">Montagem</h1>
                            <p className="text-md pb-4 text-white">
                                Serviços de montagem que transcendem as expectativas, 
                                destacando-nos como referência em excelência e qualidade. 
                                Nossa equipe dedicada é impulsionada pela paixão por 
                                motores e pela busca incessante pela perfeição técnica.
                            </p>
                        </div>
                        <div className="lg:w-2/4 md:w-2/4 w-full">
                            <img src={Montagem} alt="Homem desmontando um motor" className="mx-auto"></img>
                        </div>
                    </div>
                </section>
            </main>
            <footer>
                <Footer />
            </footer>
        </div>
    )
}