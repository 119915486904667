export const userDevice = () => ({
    getMapLink: () => {
        const userAgent = navigator.userAgent;
        const mapsAndroid = "https://www.google.com/maps/place/Retificadora+Maring%C3%A1+Ltda+Retifica+de+Motores+Diesel+Gasolina/@-23.4196128,-51.9365043,17z/data=!3m1!4b1!4m6!3m5!1s0x94ecd0d47c0d0305:0xe8a55a55507efe80!8m2!3d-23.4196128!4d-51.9339294!16s%2Fg%2F1tj7g6b3?entry=ttu"
        const mapsIos = "https://maps.apple.com/place?address=Rua%20Joubert%20de%20Carvalho,%2076,%20Centro,%20Maring%C3%A1%20-%20PR,%2087013-200,%20Brasil&auid=11247156817621446416&ll=-23.420072,-51.934199&lsp=9902&q=Retificadora%20Maring%C3%A1&_ext=CioKBAgEEBkKBAgFEAMKBAgGEGUKBAgKEAAKBAhSEAEKBAhVEBIKBAhZEAUSJik0bf/onmw3wDFJUSkGLfhJwDk4wrMwUmo3wEGl05087PZJwFAE&t=m";
    
        if (userAgent.includes("iPhone") || userAgent.includes("iPad") || userAgent.includes("iPod")) {
            return mapsIos;
        } else {
            return mapsAndroid;
        }
    
    }
})