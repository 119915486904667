import { NavBar } from "../../Components/NavBar"
import Navegar from "../../img/icons/navegar.svg";
import { useRef, useState } from "react";
import copy from 'copy-to-clipboard'
import { Footer } from "../../Components/Footer";
import { userDevice } from "../../UserDevice/userDevice";

let t = true;
let canCopyAddres = true;

export const Location = () => {

    const [loadingMap, setLoadingMap] = useState(false);

    const device = userDevice();
    const mapsNow = device.getMapLink();

    let address = "Rua Joubert de Carvalho, 76 - Zona 01, Maringá - PR, 87013-200";

    
    const copiedAddres = useRef<HTMLDivElement | null>(null);

    function copyToClipBoard() {
        if (canCopyAddres) {
            copy(address);

            if (copiedAddres.current) {
                    copiedAddres.current.style.visibility = 'visible';
                    canCopyAddres = false;
                    setTimeout(() => {
                        if (copiedAddres.current) {
                            copiedAddres.current.style.visibility = 'hidden';
                            canCopyAddres = true;
                        }
                    }, 2000);
            }
        }
    }

    function mapLoaded() {
        setLoadingMap(true);
    }


    return (
        <div className="bg-primary w-screen font-poppins font-light">
            <header>
                <NavBar />
            </header>
            <main>
                <section className="lg:px-0 px-5">
                    <div className="lg:relative w-full lg:pt-0 pt-10">
                        <div className="w-full h-[calc(50svh-2rem)] bg-secondary-focus lg:block hidden"></div>
                        <div className="w-full lg:h-[calc(50svh-2rem)] h-0 bg-primary"></div>
                        <div className="lg:w-[1024px] lg:px-0 flex lg:flex-row flex-col lg:gap-0 gap-10 shadow lg:absolute lg:top-1/2 lg:left-1/2 lg:translate-x-[-50%] lg:translate-y-[-50%]">
                            <div className="relative flex flex-col gap-10 bg-primary items-center w-full p-3">
                                <div className={`w-[calc(100%-24px)] rounded-lg left-3 h-[300px] absolute bg-base-100 ${loadingMap ? 'hidden' : 'block'}`}></div>
                                <iframe className="rounded-lg mx-auto" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3661.131738114411!2d-51.936509689071926!3d-23.419607856311856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ecd0d47c0d0305%3A0xe8a55a55507efe80!2sRetificadora%20Maring%C3%A1%20Ltda%20Retifica%20de%20Motores%20Diesel%20Gasolina!5e0!3m2!1spt-BR!2sbr!4v1699020443205!5m2!1spt-BR!2sbr" onLoad={mapLoaded} width="100%" height="300" loading="lazy"></iframe>
                                <a href={mapsNow} target="_blank" className="bg-accent text-primary flex gap-5 px-5 py-2 lg:w-1/2 justify-center items-center">
                                    <p>Marcar no mapa</p>
                                    <img src={Navegar}></img>
                                </a>
                            </div>
                            <div className="lg:w-10/12 w-full bg-secondary p-10">
                                <h1 className="text-primary text-3xl font-medium pb-10">Nossa localização</h1>
                                <address>
                                    <div className="text-primary not-italic flex flex-col text-lg gap-1">
                                        <div><b>Rua:</b> Joubert de Carvalho</div>
                                        <div><b>Número:</b> 76</div>
                                        <div><b>Bairro:</b> Zona 01</div>
                                        <div><b>CEP:</b> 87013-200</div>
                                        <div><b>Maringá - PR</b></div>
                                    </div>
                                    <button onClick={copyToClipBoard} className="text-primary border-b-1 bordert-l-0 border-t-0 border-s-0 border-r-0 border pt-10">Copiar localização</button>
                                    <div ref={copiedAddres} className="text-success pt-3 invisible">Endereço copiado!</div>
                                </address>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <footer>
                <Footer />
            </footer>
        </div>
    )
}