import React from 'react';
import logo from './logo.svg';
import { Route, Routes } from 'react-router-dom';
import { Home } from './pages/Home';
import { Company } from './pages/Company';
import { Services } from './pages/Services';
import { Location } from './pages/Location';
import { Contact } from './pages/Contact';

import './App.css';
import { ScrollTop } from './RouteConfig/ScrollTop';
import { ChangePageTitle } from './RouteConfig/ChangePageTitle';

function App() {
  return (
    <div className="App" data-theme="main">
      <Routes>
        {/* <ScrollTop /> */}
        <Route path="/" element={<> <ScrollTop /> <ChangePageTitle title='Retificadora Maringá' /> <Home /></>} />
        <Route path="/empresa" element={<> <ScrollTop /> <ChangePageTitle title='Retificadora Maringá - Empresa' /> <Company /> </>} />
        <Route path="/servicos" element={<> <ScrollTop /> <ChangePageTitle title='Retificadora Maringá - Serviços' /> <Services /> </>} />
        <Route path="/localizacao" element={<> <ScrollTop /> <ChangePageTitle title='Retificadora Maringá - Localização' /> <Location /> </>} />
        <Route path="/contato" element={<> <ScrollTop /> <ChangePageTitle title='Retificadora Maringá - Contato' /> <Contact /> </>} />
      </Routes>
    </div>
  );
}

export default App;
