import Biela from '../img/icons/biela.png'
import Montagem from '../img/icons/montagem.png'
import Chave from '../img/icons/chave.png'

type Props = {
    handleBUttonCardClick: (div: React.RefObject<HTMLDivElement>) => void,
    usinagemRef: React.RefObject<HTMLDivElement>,
    desmontagemRef: React.RefObject<HTMLDivElement>,
    montagemRef: React.RefObject<HTMLDivElement>
}

export const CardsServices = ({ props }:{props: Props}) => {


    return (
        <div className="lg:w-[1024px] px-5 mx-auto       py-20">
            <ul className="w-full flex flex-wrap gap-5 lg:justify-between justify-around">

                <li className="w-[310px] rounded-lg h-40 flex-grow-0 box-border bg-primary shadow-md border-2 border-base-100 transition duration-600 hover:bg-base-100 select-none" >
                    <button onClick={() => { props.handleBUttonCardClick(props.usinagemRef) }} className='w-full h-full flex gap-5 justify-between items-center py-2 px-8'>
                        <div className='overflow-hidden shrink-0'>
                            <img src={Biela}></img>
                        </div>
                        <div className='w-[calc(100%-80px)]'>
                            <h1 className='font-medium text-xl pb-5 text-[#b30000]'>Usinagem</h1>
                            <h2 className="underline">Conhecer  </h2>
                        </div>
                    </button>
                </li>


                <li className="w-[310px] rounded-lg h-40 box-border bg-primary shadow-md border-2 border-base-100 transition duration-600 hover:bg-base-100 select-none" >
                    <button onClick={() => { props.handleBUttonCardClick(props.montagemRef) }} className='w-full h-full flex gap-5 justify-between items-center py-2 px-8'>
                        <div className='overflow-hidden shrink-0'>
                            <img src={Montagem}></img>
                        </div>
                        <div className='w-[calc(100%-80px)]'>
                            <h1 className='font-medium text-xl pb-5 text-[#b30000]'>Montagem</h1>
                            <h2 className="underline">Conhecer  </h2>
                        </div>
                    </button>
                </li>


                <li className="w-[310] rounded-lg h-40 box-border bg-primary shadow-md border-2 border-base-100 transition duration-600 hover:bg-base-100 select-none" >
                    <button onClick={() => { props.handleBUttonCardClick(props.desmontagemRef) }} className='w-full h-full flex gap-5 justify-between items-center py-2 px-8'>
                        <div className='overflow-hidden shrink-0'>
                            <img src={Chave}></img>
                        </div>
                        <div className='w-[calc(100%-80px)]'>
                            <h1 className='font-medium text-xl pb-5 text-[#b30000]'>Desmontagem</h1>
                            <h2 className="underline">Conhecer  </h2>
                        </div>
                    </button>

                </li>
                
            </ul>
        </div>
    )
}