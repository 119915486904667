
import { useState} from "react";
import { NavBar } from "../../Components/NavBar";
import { Carrousel } from "../../Components/Carrousel";
import Effect from "../../img/pictures/effects_blue.jpg";
import Canecos from "../../img/pictures/canecos.png";
import MotorOleo from "../../img/pictures/motoroleo.png";
import { Cards } from "../../Components/Cards";
import { Footer } from "../../Components/Footer";
import { Link } from "react-router-dom";
import { userDevice } from "../../UserDevice/userDevice";


export const Home = () => {

    const device = userDevice();
    const mapsNow = device.getMapLink();

    return (
        <div className="bg-primary w-screen font-poppins font-light">
            <header>
                <NavBar />
            </header>
            <main>
                <section>
                    <Carrousel />
                </section>
                <section>
                    <div className="lg:w-[1024px] px-5 mx-auto flex lg:flex-row md:flex-row flex-col justify-between gap-10 items-center pb-20">
                        <div className="lg:w-1/2 lg:w-1/2 w-full">
                            <h1 className="text-3xl font-semibold lg:pt-20 md:pt-20 pt-10 pb-5">
                                A Maestria de Seis Décadas: A Perfeição em Retífica de Motores
                            </h1>
                            <p className="text-lg pb-5">
                                Bem-vindo à Retificadora Maringá, onde a experiência e a excelência se 
                                encontram para criar uma jornada incomparável na retífica de motores. Há mais de 60 anos, 
                                nossa empresa tem sido sinônimo de qualidade, confiabilidade e inovação no universo automotivo.
                            </p>

                            <Link to="/contato"> <button className="bg-secondary text-primary p-2">Entrar em contato</button> </Link>
                        </div>
                        <div className="w-1/2 h-full lg:pt-14 md:pt-14 pt-0 lg:flex md:flex hidden justify-end items-center shrink-0">
                            <img src={MotorOleo} alt="Motor com óleo" />
                        </div>
                    </div>
                </section>
                <section className="bg-neutral">
                    <Cards />
                </section>
                <section>
                    <div className="lg:w-[1024px] px-5 mx-auto py-20">
                        <ul className="w-full flex lg:flex-row md:flex-row flex-col gap-10">
                            <li className="lg:w-1/2 md:w-1/2 border-2 border-secondary-focus p-10 flex flex-col items-center text-center rounded-xl">
                                <h1 className="text-2xl font-semibold pb-10">Desempenho Revitalizado</h1>
                                <p className="text-lg">
                                    Revitalize o desempenho do seu veículo com a retífica 
                                    de motores conosco. Nosso processo meticuloso 
                                    corrige desgastes, garantindo eficiência, prolongando a vida útil 
                                    e reduzindo emissões para uma condução suave e econômica.
                                </p>
                            </li>

                            <li className="lg:w-1/2 md:w-1/2 border-2 border-secondary-focus p-10 flex flex-col items-center text-center rounded-xl">
                                <h1 className="text-2xl font-semibold pb-10">A Arte da Retífica</h1>
                                <p className="text-lg">
                                    Na Retificadora Maringá, a retífica de motores é mais do que manutenção; 
                                    é uma verdadeira arte. Nossos técnicos, apaixonados por excelência, elevam 
                                    o desempenho do motor com precisão e paixão, combinando tradição e tecnologia 
                                    para uma experiência de condução superior.
                                </p>
                            </li>
                        </ul>
                    </div>
                </section>
                <section> 
                    <div className="flex" style={{ backgroundImage: `url(${Effect})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                        <div className="w-full lg:h-64 py-8 lg:px-0 px-10 bg-neutral/50">
                            <div className="lg:w-[1024px] mx-auto flex gap-10 h-full">
                                <div className="inline-block shrink-0 lg:block hidden">
                                    <img src={Canecos} className=""></img>
                                </div>
                                <div className="w-7/12 text-primary inline-block align-top w-full h-full">
                                    <div className="flex justify-between flex-col lg:gap-3 gap-5 h-full">
                                        <h2 className="text-lg font-medium">Eleve o desempenho do seu motor a um novo patamar</h2>
                                        <h1 className="text-2xl font-semibold">Faça seu <span className="text-info">orçamento agora</span> e experimente a excelência dos nossos serviços</h1>
                                        <a target="_blank" href="https://api.whatsapp.com/send?phone=5544999859230&text=Olá estou visitando o website e gostaria de tirar algumas dúvidas" className="w-min vertical bg-info px-4 py-1 rounded-full text-lg font-medium">WhatsApp</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <footer>
                <Footer />
            </footer>
        </div>
    )
}