import logoDefault from '../img/logos/logo-ret-padrao.svg';
import whatsappGreen from '../img/icons/whatsapp-green.svg';
import emailIcon from '../img/icons/email.svg';
import WhatsappIcon from '../img/icons/whatsapp_nav.png';
import telefoneIcon from '../img/icons/telefone_nav.png';
import { useState } from 'react';
import '.././styles/NavBar.css';
import { Link } from 'react-router-dom';


export const NavBar = () => {
    
    const [visible, setVisible] = useState(false);
    const htmlElement = document.querySelector('html');
    
    function toggleMenu() {
        if (visible) {
            setVisible(false);
            if (htmlElement) {
                htmlElement.style.overflow = 'unset';
            } 
        } else {
            setVisible(true);
            if (htmlElement) {
                htmlElement.style.overflow = 'hidden';
            } 
        }
    }

    return (
        <div className={`${ visible? "fixed":"static" } flex w-full border-box items-center bg-primary z-50 justify-between h-16 py-4 lg:px-10 shadow `}>
            <div className='lg:w-[1024px] px-5 w-full mx-auto flex border-box items-center justify-between'>
                <a id='logo' href='/'><img src={logoDefault} alt='Logo da Retificadora Maringá'></img></a>
                <div className='flex gap-5 items-center'>
                    <nav>
                        <button onClick={toggleMenu} className='flex items-center gap-2 outline-none lg:hidden'>Menu
                            <span className={`btn-menu ${ visible ? 'active btn-icon-active-before btn-icon-active-after':'' }`}></span>
                        </button>
                        <div id='nav' className={`z-30 gap-2 absolute lg:static border-box px-4 w-full top-16 bg-secondary lg:bg-inherit right-0 block text-base-100 overflow-y-hidden overflow-x-hidden invisible lg:visible lg:h-auto h-0 ${ visible ? 'active':''}`}>
                            <ul className='lg:hidden max-h-[60svh] overflow-y-auto text-lg'>
                                <Link to="/"><li className='py-4 mx-auto border-b border-primary' onClick={toggleMenu}>Início</li></Link>
                                <Link to="/servicos"><li className='py-4 mx-auto border-b border-primary' onClick={toggleMenu}>Serviços</li></Link>
                                <Link to="/empresa"><li className='py-4 mx-auto border-b border-primary' onClick={toggleMenu}>Empresa</li></Link>
                                <Link to="/localizacao"><li className='py-4 mx-auto border-b border-primary' onClick={toggleMenu}>Localização</li></Link>
                                <Link to="/contato"><li className='py-4 mx-auto border-b border-primary' onClick={toggleMenu}>Contato</li></Link>
                            </ul>

                            <ul className='lg:flex gap-5 hidden max-h-none text-neutral text-lg'>
                                <Link to="/"><li className=''>Início</li></Link>
                                <Link to="/servicos"><li className=''>Serviços</li></Link>
                                <Link to="/empresa"><li className=''>Empresa</li></Link>
                                <Link to="/localizacao"><li className=''>Localização</li></Link>
                                <Link to="/contato"><li className=''>Contato</li></Link>
                            </ul>

                            <address className='lg:hidden pt-5 not-italic'>
                                <a target='_blank' href="https://api.whatsapp.com/send?phone=5544999859230&text=Olá estou visitando o website e gostaria de tirar algumas dúvidas" className='flex gap-5 pt-5'>
                                    <img src={WhatsappIcon} alt='Ícone do whatsapp'></img>
                                    <p className='border-b border-primary'>(44) 99985-9230</p>
                                </a>
                                <a href="tel:+554432263737" className='flex gap-5 pt-5'>
                                    <img src={telefoneIcon} alt='Ícone de telefone'></img>
                                    <p className='border-b border-primary'>(44) 3226-3737</p>
                                </a>
                                <a href="tel:+554432203737" className='flex gap-5 pt-5'>
                                    <img src={telefoneIcon} alt='Ícone de telefone'></img>
                                    <p className='border-b border-primary'>(44) 3220-3737</p>
                                </a>
                            </address>

                            <aside className='lg:hidden'>
                                <a target='_blank' href="https://api.whatsapp.com/send?phone=5544999859230&text=Olá estou visitando o website e gostaria de tirar algumas dúvidas" className='absolute bottom-0 right-0 px-5 py-3 flex gap-3'>
                                    <div className='bg-base-100 text-neutral self-center px-3 py-1 rounded-lg'>Fale conosco</div>
                                    <img src={whatsappGreen} className='w-13' alt='ícone do aplicativo WhatsApp'></img>
                                </a>
                            </aside>
                        </div>
                        
                    </nav>
                </div>
            </div>
        </div>
    )
}