import { NavBar } from "../../Components/NavBar"
import ContactUs from '../../img/pictures/contactus.jpg'
import Certo from '../../img/icons/certo.png'
import WhatsappContact from '../../img/icons/whatsapp_contact.png'
import TelefoneContact from '../../img/icons/telefone_contact.png'
import EmailContact from '../../img/icons/email_contact.png'
import { Footer } from "../../Components/Footer"

export const Contact = () => {
    return (
        <div className="bg-primary w-screen font-poppins font-light">
            <header>
                <NavBar />
            </header>
            <main>
                <section>
                    <div className="h-64" style={{ backgroundImage: `url(${ContactUs})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                        <div className="h-full w-full bg-neutral/60">
                            <div className="lg:w-[1024px] flex items-center h-full mx-auto text-primary">
                                <div className="p-5">
                                    <h1 className="lg:text-7xl md:text-6xl text-5xl font-semibold text-primary">Contate-nos</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="lg:w-[1024px] px-5 pt-14 pb-10 mx-auto flex lg:flex-row md:flex-row flex-col lg:items-start md:items-start items-center gap-10">
                        <div className="lg:w-7/12 md:w-7/12 w-full">
                            <h1 className="font-semibold text-3xl pb-5">Você Sabe Por Que Precisa Dos Nossos Serviços?</h1>
                            <p className="pb-2 text-lg">
                                Quando se trata de manter seu veículo funcionando 
                                com eficiência e segurança, a manutenção do motor é 
                                fundamental. E é aqui que entramos em cena.
                            </p>
                            <p className="text-lg">
                                Somos especialistas em retificação de motores, 
                                com anos de experiência e uma reputação de excelência.
                            </p>
                        </div>
                        <div className="lg:w-5/12 md:w-5/12 w-full">
                            <ul>
                                <li className="flex gap-5 pb-4">
                                    <div className="shrink-0">
                                        <img src={Certo}></img>
                                    </div>
                                    <div className="w-full pt-1.5 border-b border-neutral/20">
                                        <p className="pb-2 text-lg font-medium">Qualidade e precisão</p>
                                        <div className="pb-2">técnicos altamente qualificados</div>
                                    </div>
                                </li>
                                <li className="flex gap-5 pb-4">
                                    <div className="shrink-0">
                                        <img src={Certo}></img>
                                    </div>
                                    <div className="w-full pt-1.5 border-b border-neutral/20">
                                        <p className="pb-2 text-lg font-medium">Vida útil do motor</p>
                                        <div className="pb-2">Prolonga a vida útil, evitando reparos futuros</div>
                                    </div>
                                </li>
                                <li className="flex gap-5 pb-4">
                                    <div className="shrink-0">
                                        <img src={Certo}></img>
                                    </div>
                                    <div className="w-full pt-1.5">
                                        <p className="pb-2 text-lg font-medium">Economia de combustível</p>
                                        <div className="pb-2">Rode mais pagando menos</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="bg-secondary">
                    <div className="lg:w-[1024px] mx-auto py-10">
                        <ul className="flex lg:flex-row flex-col lg:gap-10 gap-16 justify-evenly">
                            {/* <li className="flex flex-col items-center gap-5 rounded-md">
                                <div className="shrink-0"><img src={EmailContact}></img></div>
                                <h5 className="text-primary font-medium text-lg">Email:</h5>
                                <p className="text-primary font-medium"></p>
                            </li> */}
                            <li className="flex flex-col items-center gap-5 rounded-md">
                                <div className="shrink-0"><img src={TelefoneContact}></img></div>
                                <h5 className="text-primary font-medium text-lg">Telefones:</h5>
                                <a href="tel:+554432263737" className="text-primary font-medium border-b border-primary pb-1">{"(44) 3226-3737"}</a>
                                <a href="tel:+554432203737" className="text-primary font-medium border-b border-primary pb-1">{"(44) 3220-3737"}</a>
                            </li>
                            <li className="flex flex-col items-center gap-5 rounded-md">
                                <div className="shrink-0"><img src={WhatsappContact}></img></div>
                                <h5 className="text-primary font-medium text-lg">WhatsApp:</h5>
                                <a target="_blank" href="https://api.whatsapp.com/send?phone=5544999859230&text=Olá estou visitando o website e gostaria de tirar algumas dúvidas" className="text-primary font-medium border-b border-primary pb-1">{"(44) 99985-9230"}</a>
                            </li>
                        </ul>
                    </div>
                </section>
            </main>
            <footer>
                <Footer />
            </footer>
        </div>
    )
}