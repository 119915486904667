import RetificadoraLogo from '../img/logos/logo-ret-padrao.svg'
import Telefone from '../img/icons/telefone_footer.png'
import Localizacao from '../img/icons/localizacao_footer.png'
import Email from '../img/icons/email_footer.png'
import Whatsapp from '../img/icons/whatsapp_footer.png';
import { Link } from 'react-router-dom'


export const Footer = () => {


    return (
        <div className="w-full px-5 py-20 bg-base-100 font-poppins">
            <div className="lg:w-[1024px] mx-auto flex lg:flex-row md:flex-row flex-col gap-10 px-5">
                
                <div className="lg:w-1/3 md:w-1/3 w-full flex flex-col items-center">
                    <img src={RetificadoraLogo} alt='Logo da Retificadora Maringá' className='pb-3'></img>
                    <div className='text-sm lg:text-start md:text-start text-center'>
                        Com 60 anos de experiência no mercado, nossa empresa tem uma trajetória 
                        rica e sólida no mercado de retífica de motores e serviços automotivos.
                    </div>
                </div>
                <div className='lg:w-1/3 md:w-1/3 w-full flex flex-col items-center'>
                    <h5 className='font-semibold uppercase pb-3'>Navegação</h5>
                    <ul className='flex lg:flex-col md:flex-col flex-row gap-3 flex-wrap justify-center'>
                        <li className='border-b border-neutral pb-1 hover:text-secondary-focus hover:border-secondary-focus'><Link to="/">Início</Link></li>
                        <li className='border-b border-neutral pb-1 hover:text-secondary-focus hover:border-secondary-focus'><Link to="/servicos">Serviços</Link></li>
                        <li className='border-b border-neutral pb-1 hover:text-secondary-focus hover:border-secondary-focus'><Link to='/empresa'>Empresa</Link></li>
                        <li className='border-b border-neutral pb-1 hover:text-secondary-focus hover:border-secondary-focus'><Link to='/localizacao'>Localização</Link></li>
                        <li className='border-b border-neutral pb-1 hover:text-secondary-focus hover:border-secondary-focus'><Link to='/contato'>Contato</Link></li>
                    </ul>
                </div>
                <address className='lg:w-1/3 md:w-1/3 w-full flex flex-col items-center not-italic'>
                    <h5 className='font-semibold uppercase pb-3'>Contato</h5>
                    <ul className='flex flex-col gap-3'>

                        <li className='flex gap-5'>
                            <div className='shrink-0'><img src={Localizacao}></img></div>
                            <p className=''>Rua Joubert de Carvalho, 76 - Zona 01, Maringá - PR, 87013-200</p>
                        </li>

                        <li className='flex gap-5'>
                            <div className='shrink-0'><img src={Email}></img></div>
                            <p className=''>enderecodeemail@gmail.com</p>
                        </li>

                        <li className='flex gap-5'>
                            <div className='shrink-0'><img src={Whatsapp} className='shrink-0'></img></div>
                            <a target="_blank" href="https://api.whatsapp.com/send?phone=5544999859230&text=Olá estou visitando o website e gostaria de tirar algumas dúvidas" className='border-b border-neutral pb-1 hover:text-secondary-focus hover:border-secondary-focus'>{"(44) 99985-9230"}</a>
                        </li>

                        <li className='flex gap-5'>
                            <div className='shrink-0'><img src={Telefone} className='shrink-0'></img></div>
                            <a href="tel:+554432203737" className='border-b border-neutral pb-1 hover:text-secondary-focus hover:border-secondary-focus'>{"(44) 3220-3737"}</a>
                        </li>

                        <li className='flex gap-5'>
                            <div className='shrink-0'><img src={Telefone} className='shrink-0'></img></div>
                            <a href="tel:+554432263737" className='border-b border-neutral pb-1 hover:text-secondary-focus hover:border-secondary-focus'>{"(44) 3226-3737"}</a>
                        </li>
                    </ul>
                </address>
            </div>
        </div>
    )
}