import { useState } from "react";
import AutoCenter from '../img/pictures/autocenter2.jpg';
import Retificando from '../img/pictures/retificando2.jpg';
import Balanceamento from '../img/pictures/balanceamento2.jpg';
import SetaDireita from '../img/icons/seta-direita.svg';
import SetaEsquerda from '../img/icons/seta-esquerda.svg';

interface Props {
    className?: string;
    seconds?: number;
}

export const Carrousel = ({className = "w-full h-full", seconds = 0.2,}: Props) => {
    const [index, setIndex] = useState(0);
    const [nextIndex, setNexIndex] = useState(0);
    const [translate, setTranslate] = useState("0%");
    const [secs, setSecs] = useState(0);

    const content = [
        {
            image: Retificando,
            title: "Retífica de Motores",
            subTitle: "Nacionais e importados",
        },
        {
            image: AutoCenter,
            title: "AutoCenter",
            subTitle: "Serviços em geral",
        },
        {
            image: Balanceamento,
            title: "Balanceamento",
            subTitle: "Veículos em Geral",
        }
    ]

    function resetIndex(i: number): number {
        if (Math.abs(i) > content.length - 1) {
          return i % content.length;
        } else {
          return i;
        }
      }
    
      function goRight() {
        setNexIndex(1);
        setTranslate("-100%");
        setSecs(seconds);
        setTimeout(() => {
          setIndex(resetIndex(index + 1));
          setTranslate("0%");
          setSecs(0);
        }, seconds * 1000);
      }
    
      function goLeft() {
        setNexIndex(-1);
        setTranslate("100%");
        setSecs(seconds);
        setTimeout(() => {
          setIndex(resetIndex(index - 1));
          setTranslate("0%");
          setSecs(0);
        }, seconds * 1000);
      }


    return (
        <div className={className}>
            <div className="lg:h-[calc(100vh-4rem)] w-full h-64 overflow-hidden flex">
                <div 
                    className={"w-full h-full flex"} 
                    style={{
                        transform: `translateX(${translate})`,
                        transition: `${secs}s`,
                        flexDirection: `${nextIndex > 0 ? 'row' : 'row-reverse'}`
                    }}
                >
                    
                    <div className="w-screen" style={{backgroundImage: `url(${content[resetIndex(Math.abs(index))].image})`, backgroundSize: 'cover'}}>
                        <div className="bg-gradient-to-b from-neutral/40 to-neutral h-full flex items-center justify-center flex-col">
                            <div className='h-full w-full flex py-3 w-screen justify-center items-center pb-10 select-none'>
                                <button className='rounded-full w-14 h-14 flex items-center justify-center h-full w-2/12' onClick={goLeft}>
                                    <img src={SetaEsquerda} alt='Seta apontando para esquerda'></img>
                                </button>
                                <div className='flex flex-col items-center w-8/12 px-4 text-center'>
                                    <h1 className='text-white lg:text-5xl text-xl font-semibold'>{content[resetIndex(Math.abs(index))].title}</h1>
                                    <p className='text-white lg:text-2xl text-md'>{content[resetIndex(Math.abs(index))].subTitle}</p>
                                </div>
                                <button className='rounded-full w-14 h-14 flex items-center justify-center h-full w-2/12' onClick={goRight}>
                                    <img src={SetaDireita} alt='Seta apontando para a direita'></img>
                                </button>
                            </div>
                        </div>
                    </div>


                    <div className="w-screen" style={{backgroundImage: `url(${content[resetIndex(Math.abs(index + nextIndex))].image})`, backgroundSize: 'cover'}}>
                        <div className="bg-gradient-to-b from-neutral/40 to-neutral h-full flex items-center justify-center flex-col">
                            <div className='flex py-3 w-screen justify-center items-center pb-10 select-none'>
                                <button className='rounded-full w-14 h-14 flex items-center justify-center h-full w-2/12' onClick={goLeft}>
                                    <img src={SetaEsquerda} alt='Seta apontando para esquerda'></img>
                                </button>
                                <div className='flex flex-col items-center w-8/12'>
                                    <h1 className='text-white lg:text-5xl text-xl font-semibold'>{content[resetIndex(Math.abs(index + nextIndex))].title}</h1>
                                    <p className='text-white lg:text-2xl text-md'>{content[resetIndex(Math.abs(index + nextIndex))].subTitle}</p>
                                </div>
                                <button className='rounded-full w-14 h-14 flex items-center justify-center h-full w-2/12' onClick={goRight}>
                                    <img src={SetaDireita} alt='Seta apontando para a direita'></img>
                                </button>
                            </div>
                        </div>
                    </div>







                    
                </div>
            </div>
        </div>
    )
}