import { Footer } from "../../Components/Footer";
import { NavBar } from "../../Components/NavBar"
import engine from '../../img/pictures/motor.png';
import service1 from '../../img/pictures/servico1.jpg'
import service2 from '../../img/pictures/servico2.jpg'
import service4 from '../../img/pictures/servico4.jpg'
import Empresa from '../../img/pictures/empresa.jpg'
import Retificadora from '../../img/pictures/empresa.png'
import Missao from '../../img/icons/missao.png';
import Visao from '../../img/icons/visao.png';
import Valores from '../../img/icons/valores.png';
import { Link } from "react-router-dom";

export const Company = () => {
    return (
        <div className="bg-primary w-screen font-poppins font-light">
            <header>
                <NavBar />
            </header>

            <main>
                <section className="bg-neutral">  
                    <div className="h-64 mx-auto" style={{ backgroundImage: `url(${Empresa})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                        <div className="w-full h-full bg-neutral/60">
                            <div className="lg:w-[1024px] flex items-center h-full mx-auto text-primary">
                                <div className="p-5">
                                    <h2 className="pb-4 text-xl">Sobre a nossa</h2>
                                    <h1 className="lg:text-7xl md:text-7xl text-6xl font-semibold text-primary">Empresa</h1>
                                </div>
                            </div>
                        </div>
                    </div>   
                </section>

                <section className="pb-48">
                    <div className="lg:w-[1024px] px-5 py-10 flex lg:flex-row md:flex-row flex-col mx-auto gap-10 items-center justify-center">
                        <div className="lg:w-1/2 md:w-1/2 w-full">
                            <h1 className="text-4xl font-medium">Mais de <div className="font-bold">60 anos de experiência</div></h1>
                            <p className="text-lg pt-6 pb-5">
                                Somos especialistas em retificação de motores. 
                                Nosso compromisso com a excelência e o profissionalismo 
                                nos permite oferecer a você serviços de alta qualidade. 
                                Seu motor, nossa prioridade.
                            </p>
                            <Link to="/contato"> <button className="bg-secondary text-primary p-2">Entrar em contato</button> </Link>
                        </div>
                        <div className="lg:w-1/2 md:w-1/2 w-full flex lg:justify-end md:justify-end justify-center">
                            <img src={Retificadora}></img>
                        </div>
                    </div>
                </section>

                <section className="bg-neutral py-10">
                    <div className="lg:w-[1024px] mx-auto px-5">
                        <ul className="flex lg:flex-row md:flex-row flex-col items-center mt-[-10.5rem] gap-10">
                            <li className="lg:w-1/3 md:w-1/3 w-[calc(100%-10%)] rounded-md min-h-64 bg-base-100 shadow-xl border border-base-200 p-4 flex flex-col items-center justify-center">
                                <div className="pb-4"><img src={Missao} alt="Missão da empresa"></img></div>
                                <h5 className="font-bold">Missão</h5>
                                <p className="text-center">
                                    Fornecimento de serviços premium de retificação de 
                                    motores, com foco na total satisfação do cliente.
                                </p>
                            </li>
                            <li className="lg:w-1/3 md:w-1/3 w-[calc(100%-10%)] rounded-md min-h-64 bg-base-100 shadow-lg border border-base-200 p-4 flex flex-col items-center justify-center">
                                <div className="pb-4"><img src={Visao} alt="Visão da empresa"></img></div>
                                <h5 className="font-bold">Visão</h5>
                                <p className="text-center">
                                    Fornecimento de serviços premium de retificação de 
                                    motores, com foco na total satisfação do cliente.
                                </p>
                            </li>
                            <li className="lg:w-1/3 md:w-1/3 w-[calc(100%-10%)] rounded-md min-h-64 bg-base-100 shadow-lg border border-base-200 p-4 flex flex-col items-center justify-center">
                                <div className="pb-4"><img src={Valores} alt="Valor da empresa"></img></div>
                                <h5 className="font-bold">Valores</h5>
                                <p className="text-center">
                                    Fornecimento de serviços premium de retificação de 
                                    motores, com foco na total satisfação do cliente.
                                </p>
                            </li>
                        </ul>
                        <div className="text-primary text-lg pt-12 text-center">
                            <h3 className="text-xl font-medium pb-5">Qualidade que Impulsiona</h3>
                            <p className="font-extralight">
                                Elevando padrões, nossa retífica de motores é sinônimo de excelência, 
                                profissionalismo e cuidado dedicado a cada cliente. Potencializamos 
                                não apenas motores, mas a confiança e satisfação de quem escolhe a 
                                qualidade em cada serviço.
                            </p>
                        </div>
                    </div>
                </section>
            </main>

            <footer>
                <Footer />
            </footer>
        </div>
    )
}