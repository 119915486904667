import Qualidade from '../img/icons/qualiade.png';
import Motor from '../img/icons/motor.png';
import Experiencia from '../img/icons/experiencia.png';
import Cliente from '../img/icons/cliente.png';
import Especialista from '../img/icons/especialista.png';
import Ferramenta from '../img/icons/ferramenta.png';

export const Cards = () => {


    return (
        <div className="lg:w-[1024px] md:[1024px] w-full  px-5  mx-auto py-16 text-white">
            <ul className='w-full flex flex-row flex-wrap lg:gap-10 md:gap-5 gap-10 lg:justify-between md:justify-between items-start'>
                <li className='lg:w-[calc(33.33%-2.5rem)] w-screen md:w-[calc(50%-2.5rem)] w-full justify-center flex lg:gap-5 md:gap-5 gap-10 items-start'>
                    <div className='shrink-0'>
                        <img src={Motor} alt='Ícone de um motor de veículo'></img>
                    </div>
                    <div className='w-[calc(90%-80px)]'>
                        <h3 className='font-bold pb-4'>Motores</h3>
                        <p>
                            Carros, caminhões, máquinas agrícolas,
                            motores em geral.
                        </p>
                    </div>
                </li>
                <li className='lg:w-[calc(33.33%-2.5rem)] w-screen md:w-[calc(50%-2.5rem)] w-full justify-center flex lg:gap-5 md:gap-5 gap-10 items-start'>
                    <div className='shrink-0'>
                        <img src={Experiencia} alt='Ícone representando experiência'></img>
                    </div>
                    <div className='w-[calc(90%-80px)]'>
                        <h3 className='font-bold pb-4'>Experiência</h3>
                        <p>
                            Mais de 60 anos de experiência no mercado
                            de retífica de motores
                        </p>
                    </div>
                </li>
                <li className='lg:w-[calc(33.33%-2.5rem)] w-screen md:w-[calc(50%-2.5rem)] w-full justify-center flex lg:gap-5 md:gap-5 gap-10 items-start'>
                    <div className='shrink-0'>
                        <img src={Qualidade} alt='ícone representando qualidade'></img>
                    </div>
                    <div className='w-[calc(90%-80px)]'>
                        <h3 className='font-bold pb-4'>Qualidade</h3>
                        <p>
                            Para nós, qualidade é um ponto primordial
                            de todos os serviços
                        </p>
                    </div>
                </li>
                <li className='lg:w-[calc(33.33%-2.5rem)] w-screen md:w-[calc(50%-2.5rem)] w-full justify-center flex lg:gap-5 md:gap-5 gap-10 items-start'>
                    <div className='shrink-0'>
                        <img src={Ferramenta} alt='Ícone representando ferramentas'></img>
                    </div>
                    <div className='w-[calc(90%-80px)]'>
                        <h3 className='font-bold pb-4'>Equipamentos</h3>
                        <p>
                            Equipamentos de última geração
                            para garantir a excelência de
                            nossos serviços
                        </p>
                    </div>
                </li>
                <li className='lg:w-[calc(33.33%-2.5rem)] w-screen md:w-[calc(50%-2.5rem)] w-full justify-center flex lg:gap-5 md:gap-5 gap-10 items-start'>
                    <div className='shrink-0'>
                        <img src={Especialista} alt='Ícone representando especialistas'></img>
                    </div>
                    <div className='w-[calc(90%-80px)]'>
                        <h3 className='font-bold pb-4'>Especialistas</h3>
                        <p>
                            Equipe altamente treinada e
                            capacitada para todos os serviços
                        </p>
                    </div>
                </li>
                <li className='lg:w-[calc(33.33%-2.5rem)] w-screen md:w-[calc(50%-2.5rem)] w-full justify-center flex lg:gap-5 md:gap-5 gap-10 items-start'>
                    <div className='shrink-0'>
                        <img src={Cliente} alt='Ícone representando carinho pelos clientes'></img>
                    </div>
                    <div className='w-[calc(90%-80px)]'>
                        <h3 className='font-bold pb-4'>Cliente</h3>
                        <p>
                            Nossos clientes em primeiro lugar, atendendo suas
                            necessidade com uma experiência excepcional.
                        </p>
                    </div>
                </li>
                



            
            </ul>
        </div>
    )
}